<template>
  <div :class="$style.popup_wrapper">
    <div
      :class="$style.popup_container"
      @click.self="emits('hide')"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
const emits = defineEmits<{
  (e: "hide"): void;
}>();
</script>

<style lang="scss" module>
.popup_wrapper {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100dvh;
  background-color: var(--general-transparent-dark-85);
  overflow: auto;
}
.popup_container {
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-lg);
  @media screen and (max-width: $sm) {
    padding: 0;
    padding-top: var(--spacing-7xl);
    align-items: flex-end;
  }
}
</style>
